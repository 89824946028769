import moment from "moment";


export const formatDateWithZone = (unixTime) =>{
  try{
    let dateString = new Date(unixTime).toString();
   
    let startIndex = dateString.indexOf("(");
    let modifyDate = dateString.substring(0,startIndex);
    return modifyDate;
  }
  catch(err){
    console.log(err.message);
  }
  
}

export const formatDate = (unixTime) =>{
  try{
    // let dateString = new Date(unixTime).toLocaleString({ hour12: true });
    let dateString = moment(unixTime).format('lll');
    // let startIndex = dateString.indexOf("(");
    // let modifyDate = dateString.substring(0,startIndex);
    // console.log(modifyDate);
    return dateString;
  }
  catch(err){
    console.log(err.message);
  }
  
}


export const BACKEND_URL =  "https://blockstar.center/api/";
export const BACKEND_BASE_URL =  "https://blockstar.center";
export const FRONTEND_URL = "https://blockstar.center";
// export const BACKEND_URL =  "http://localhost/meet-schedule-jitsi-backend/api/";
// export const BACKEND_BASE_URL =  "http://localhost/meet-schedule-jitsi-backend";
// export const FRONTEND_URL = "http://localhost:3000";
export const JITSI_DOMAIN = "meet.blockstar.world";
export const MEET_URL = "https://blockstar.foundation/"
export const GOOGLE_API_KEY = "AIzaSyD6ZbFOWEJ4UYisRUMJweCwnbrOMQ1pu8Q";
export const GOOGLE_CLIENT_ID = "800319367336-ct5oemlo0s3pkoc5u479lltdqidl9ubs.apps.googleusercontent.com";
export const DISCOVERY = 'https://www.googleapis.com/discovery/v1/apis/youtube/v3/rest'
export const SCOPE = 'https://www.googleapis.com/auth/youtube.force-ssl';
export const FACEBOOK_APP_ID = '3019047485020223';
export const FACEBOOK_APP_SECRET = 'b9f5205208e7a1a754fe7a16da3b6860';
export const TWITCH_CLIENT_ID = 'bt0amw179sskciitpyw9bbov644o1t';
export const TWITCH_SECRET_ID = 'hrhmd9br81y8yhdxb0nw2n9wyil02l';
// export const TWITCH_REDIRECT_URL = 'https://localhost:3000/connect-stream';
export const TWITCH_REDIRECT_URL = 'https://blockstar.center/connect-stream';
export const TWITCH_SCOPE = encodeURIComponent(
  'channel:manage:broadcast channel:read:stream_key'
)
export const FB_RTMPS = 'rtmps://live-api-s.facebook.com:443/rtmp/';
export const TIKTOK_APPID = '7313324214331738118';
export const TIKTOK_CLIENT_ID = 'awgr3fowkx0oqcow';
export const TIKTOK_SECRET_ID = '1zZdSfaoZMNmxgvNUpnMo5KE8LTGi6aa';
export const TIKTOK_ENDPOINT = 'https://www.tiktok.com/v2/auth/authorize/'

// export const BLOCKSTAR_APP_ID = 'f41df4fc2a9943aa890f';
// export const BLOCKSTAR_SECRET_ID = '0e45d31ac16d9d11ec4df25c5d6948be3d06e8c';
// main
export const BLOCKSTAR_APP_ID = 'e8951a024c875a3c1753';
export const BLOCKSTAR_SECRET_ID = '97471276859cef562f5188d5d62cd332a642425';
export const BLOCKSTAR_ENDPOINT = 'https://blockstar.social/'; 
// export const BLOCKSTAR_ENDPOINT = 'http://localhost/blockstar-social/'

export const AGORA_APP_ID = "466148b10ce3402a86736ee8eb3732d7";


export const CAPTURE_OPTIONS_RECORD_SCREEN = {
  audio: true,
  video: {
    height: 1080,
    width: 1920,
    frameRate: { ideal: 24, max: 30 },
  },
}

